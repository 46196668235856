var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"teros-elevation",attrs:{"color":_vm.tableColor}},[_c('v-card-title',{staticClass:"px-5 pb-0 text-uppercase v-title-cadastro"},[_vm._v(" Resumo Recursos Humanos Por Projeto ")]),_c('v-card-text',{staticClass:"pb-6"},[_c('div',{staticClass:"d-flex pa-2 justify-between"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"table-v-action-button mr-3 pt-1",on:{"click":function($event){return _vm.doLoad()}}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v("Atualizar ")],1),_c('div',{staticClass:"table-v-action-button mr-3 pt-1",on:{"click":function($event){return _vm.exportXlsx2.apply(void 0, _vm.relatorioXlsx)}}},[_c('v-icon',[_vm._v("mdi-file-export-outline")]),_vm._v("Exportação ")],1),_c('monthly-filter',{staticStyle:{"min-width":"270px","margin-right":"5px"},on:{"change":function($event){return _vm.doLoad()}},model:{value:(_vm.competencia),callback:function ($$v) {_vm.competencia=$$v},expression:"competencia"}}),_c('servico-select',{staticClass:"mb-n1 mt-0 mr-3",staticStyle:{"max-width":"255px"},on:{"change":function($event){return _vm.doLoad()}},model:{value:(_vm.servico),callback:function ($$v) {_vm.servico=$$v},expression:"servico"}})],1),_c('div',[_c('recalcule-btn',{staticClass:"table-v-action-button pt-1",on:{"change":function($event){_vm.intervalo = $event;
            _vm.recalcularPeriodo();}}})],1)])]),(_vm.relatorioFormatado.length === 0)?[_c('span',{staticClass:"font-weight-bold white d-block pl-4 pb-4 pt-6"},[_vm._v(" Nenhum registro encontrado para exibição. ")])]:_vm._e(),_vm._l((_vm.relatorioFormatado),function(proj,idx){return [_c('span',{key:idx + 'span',staticClass:"font-weight-bold d-block pl-4 pb-4",class:{ 'pt-6': idx != 0 }},[_vm._v("Projeto: "+_vm._s(proj.projeto.titulo)+" ")]),(proj.projeto.agrupamento)?_c('p',{key:idx + 'p',staticClass:"font-weight-bold d-block pl-4 pb-2",class:{ 'pt-1': idx != 0 }},[_vm._v(" Agrupamento: "+_vm._s(proj.projeto.agrupamento)+" ")]):_vm._e(),_c('v-data-table',{key:idx,staticClass:"tabela-resumo",attrs:{"data-app":"","items":proj.colaboradores,"headers":_vm.colunas,"footer-props":{
        'items-per-page-options': [-1]
      },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
      var headers = ref.headers;
return [_c('tr',{staticClass:"table-v-tr",staticStyle:{"cursor":"default"}},_vm._l((headers),function(col){return _c('td',{key:col.value,class:("total-geral-" + (col.value)),style:({ 'text-align': col.textAlign })},[_vm._v(" "+_vm._s(col.format ? col.format(item[col.value]) : item[col.value])+" ")])}),0)]}},{key:"body.append",fn:function(append){return [_c('tr',{staticClass:"table-v-tr"},_vm._l((append.headers),function(col){return _c('th',{key:col.value,class:("total-geral-" + (col.value)),staticStyle:{"cursor":"default","font-size":"13px !important"},style:({ 'text-align': col.textAlign })},[_vm._v(" "+_vm._s(col.format ? col.format(proj.total[col.value]) : proj.total[col.value])+" ")])}),0)]}}],null,true)})]}),(_vm.relatorioFormatado.length)?[_c('div',{staticClass:"total-geral-line",style:({ backgroundColor: _vm.tableColor })},_vm._l((_vm.colunas),function(ref){
      var format = ref.format;
      var textAlign = ref.textAlign;
      var value = ref.value;
return _c('div',{key:value,class:("total-geral total-geral-" + value),staticStyle:{"cursor":"default","font-size":"13px !important"},style:({ 'text-align': textAlign })},[_vm._v(" "+_vm._s(format ? format(_vm.totalGeral[value]) : _vm.totalGeral[value])+" ")])}),0)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }