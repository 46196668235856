<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Resumo Recursos Humanos Por Projeto
    </v-card-title>
    <v-card-text class="pb-6">
      <div class="d-flex pa-2 justify-between">
        <div class="row">
          <div class="table-v-action-button mr-3 pt-1" @click="doLoad()">
            <v-icon>mdi-refresh</v-icon>Atualizar
          </div>
          <div
            @click="exportXlsx2(...relatorioXlsx)"
            class="table-v-action-button mr-3 pt-1"
          >
            <v-icon>mdi-file-export-outline</v-icon>Exportação
          </div>
          <!-- <v-menu offset-y dense>
            <template>
              
            </template>
             <v-list>
              <v-list-item
                style="cursor: pointer"
                @click="exportCsv(arrayToPrint, headerToPrint)"
              >
                <v-list-item-title class="v-label-input">
                  <v-icon left>mdi-file-delimited-outline</v-icon>Exportar CSV
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                style="cursor: pointer"
                @click="exportXlsx2(...relatorioXlsx)"
              >
                <v-list-item-title class="v-label-input">
                  <v-icon left>mdi-file-excel-outline</v-icon>Exportar XLSX
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
          <monthly-filter
            v-model="competencia"
            @change="doLoad()"
            style="min-width: 270px; margin-right: 5px"
          >
          </monthly-filter>
          <servico-select
            class="mb-n1 mt-0 mr-3"
            style="max-width: 255px"
            v-model="servico"
            @change="doLoad()"
          ></servico-select>
        </div>
        <div>
          <recalcule-btn
            class="table-v-action-button pt-1"
            @change="
              intervalo = $event;
              recalcularPeriodo();
            "
          ></recalcule-btn>
        </div>
      </div>
    </v-card-text>
    <template v-if="relatorioFormatado.length === 0">
      <span class="font-weight-bold white d-block pl-4 pb-4 pt-6">
        Nenhum registro encontrado para exibição.
      </span>
    </template>
    <template v-for="(proj, idx) in relatorioFormatado">
      <span
        class="font-weight-bold d-block pl-4 pb-4"
        :class="{ 'pt-6': idx != 0 }"
        :key="idx + 'span'"
        >Projeto: {{ proj.projeto.titulo }}
      </span>
      <p
        v-if="proj.projeto.agrupamento"
        class="font-weight-bold d-block pl-4 pb-2"
        :class="{ 'pt-1': idx != 0 }"
        :key="idx + 'p'"
      >
        Agrupamento: {{ proj.projeto.agrupamento }}
      </p>

      <v-data-table
        :key="idx"
        class="tabela-resumo"
        data-app
        :items="proj.colaboradores"
        :headers="colunas"
        :footer-props="{
          'items-per-page-options': [-1]
        }"
        hide-default-footer
      >
        <template v-slot:item="{ item, headers }">
          <tr class="table-v-tr" style="cursor: default">
            <td
              v-for="col in headers"
              v-bind:key="col.value"
              :class="`total-geral-${col.value}`"
              :style="{ 'text-align': col.textAlign }"
            >
              {{ col.format ? col.format(item[col.value]) : item[col.value] }}
            </td>
          </tr>
        </template>
        <template v-slot:[`body.append`]="append">
          <tr class="table-v-tr">
            <th
              style="cursor: default; font-size: 13px !important;"
              v-for="col in append.headers"
              v-bind:key="col.value"
              :class="`total-geral-${col.value}`"
              :style="{ 'text-align': col.textAlign }"
            >
              {{
                col.format
                  ? col.format(proj.total[col.value])
                  : proj.total[col.value]
              }}
            </th>
          </tr>
        </template>
      </v-data-table>
    </template>

    <template v-if="relatorioFormatado.length">
      <div class="total-geral-line" :style="{ backgroundColor: tableColor }">
        <div
          style="cursor: default; font-size: 13px !important;"
          v-for="{ format, textAlign, value } in colunas"
          v-bind:key="value"
          :class="`total-geral total-geral-${value}`"
          :style="{ 'text-align': textAlign }"
        >
          {{ format ? format(totalGeral[value]) : totalGeral[value] }}
        </div>
      </div>
    </template>
  </v-card>
</template>

<script>
import * as moment from "moment";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import _ from "lodash";
import { BeneficiosEnum } from "@/core/enums/beneficios";
import { formatCPF } from "@/helpers/masks";

export default {
  components: {
    "servico-select": () => import("@/components/servico-select.vue"),
    "monthly-filter": () => import("../../components/monthly-filter.vue"),
    "recalcule-btn": () => import("../../components/recalcule-btn.vue")
  },
  directives: { mask: mask },
  computed: {
    ...mapGetters(["clientId", "selectedClient"]),
    resource() {
      return this.apiResource(
        `/v1/rh/clientes/${this.clientId}/dispendio/projetosColaborador`
      );
    },
    resourceRecalculo() {
      return this.apiResource(
        `v1/rh/clientes/${this.clientId}/dispendio/recalrhprojetos`
      );
    },
    relatorioFormatado() {
      return this.relatorio.map(row => ({
        ...row,
        total: _.reduce(
          row.colaboradores,
          (rowA, rowB) => {
            rowA.horas += rowB.horas ? rowB.horas * 1 : 0;
            rowA.valor += rowB.valor ? rowB.valor * 1 : 0;
            return rowA;
          },
          { nome: "Total", horas: 0, valor: 0 }
        )
      }));
    },
    totalGeral() {
      return this.relatorioFormatado.reduce(
        (acc, proj) => {
          acc.horas += proj.total.horas;
          acc.valor += proj.total.valor;
          return acc;
        },
        {
          nome: "Total Geral",
          cpf: null,
          escolaridade: null,
          dedicacao: null,
          horas: 0,
          valor: 0
        }
      );
    },
    relatorioXlsx() {
      let rows = this.relatorioFormatado.reduce(
        (prev, { colaboradores, total, projeto }) => {
          let content = (Array.isArray(colaboradores) ? colaboradores : []).map(
            colaborador => {
              const row = this.colunas.map(
                ({ value }) => colaborador[value] || ""
              );
              row.unshift(projeto?.titulo || "");
              return row;
            }
          );
          let totalRow = this.colunas.map(({ value }) => total[value] || "");
          totalRow.splice(1, 0, "");
          return [...prev, ...content, totalRow];
        },
        []
      );

      let styles = rows
        .reduce(
          (prev, row, rowIndex) => {
            const refs = [];

            if ([1, 2].includes(row.length)) {
              refs.push(rowIndex + 2);
            }

            row.forEach((cell, cellIndex) => {
              if (cell === "Total") {
                refs.push(rowIndex + 2);
              }

              if (typeof cell === "string" && cell.includes("R$ ")) {
                rows[rowIndex][cellIndex] = cell.replace("R$ ", "");
              }
            });

            return [...prev, ...refs];
          },
          [1]
        )
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              font: {
                bold: true
              }
            }
          }),
          {}
        );

      const columns = [
        {
          text: "Projeto",
          value: "projeto"
        },
        ...this.colunas
      ];

      return [
        rows,
        {
          columns,
          periodoCompetencia: this.competenciaReport,
          servico: this.servico,
          styles,
          exportAs: "xlsx"
        }
      ];
    },
    relatorioCsv() {
      const [rows, options] = this.relatorioXlsx;
      return [
        rows,
        {
          ...options,
          exportAs: "csv"
        }
      ];
    },
    competenciaFilter() {
      var mIni = moment(this.competencia[0]);
      var mFim = moment(this.competencia[1]);
      var diff = mFim.diff(mIni, "months");
      if (diff < 0) {
        mIni = moment(this.competencia[1]);
        mFim = moment(this.competencia[0]);
      }
      return { ini: mIni.format("YYYY-MM"), fim: mFim.format("YYYY-MM") };
    },
    colunas() {
      return [
        {
          text: "Nome",
          value: "nome"
        },
        {
          text: "CPF",
          value: "cpf",
          format: formatCPF
        },
        {
          text: "Titulação",
          value: "escolaridade"
        },
        {
          text:
            this.servico === BeneficiosEnum.LEI_DO_BEM
              ? "Dedicação"
              : "Tipo RH",
          value: "dedicacao",
          data: "dedicacao"
        },
        {
          text: "Horas",
          value: "horas",
          format: this.$options.filters.toDoubleComplete,
          textAlign: "right"
        },
        {
          text: "Valor",
          value: "valor",
          format: this.$options.filters.toCurrency,
          textAlign: "right"
        }
      ];
    },
    competenciaReport() {
      var mIni = moment(this.competencia[0]);
      var mFim = moment(this.competencia[1]);
      var diff = mFim.diff(mIni, "months");
      if (diff < 0) {
        mIni = moment(this.competencia[1]);
        mFim = moment(this.competencia[0]);
      }
      return { ini: mIni.format("MM/YYYY"), fim: mFim.format("MM/YYYY") };
    },
    contextMenuOptions() {
      const options = [
        {
          name: "Folha de Pagamento",
          limit: 1,
          show: this.userHasAccess("Colaboradores.folhaDePagamento.index"),
          cb: row => {
            this.$router.push({
              name: "folha-pagamento-colaborador",
              params: { colaboradorId: row.idColaborador }
            });
          }
        },
        {
          name: "Cálculo do Dispêndio",
          limit: 1,
          show: this.userHasAccess("Dispendios.recursosHumanos.index"),
          cb: row => {
            this.$router.push({
              name: "dispendio-rh",
              query: { colaboradorId: row.idColaborador }
            });
          }
        },
        {
          name: "sep",
          show: {
            get() {
              return (
                options.slice(0, 2).some(({ key }) => key) &&
                options.slice(3).some(({ key }) => key)
              );
            }
          }
        },
        {
          name: "De->Para",
          limit: 1,
          show: this.userHasAccess("Colaboradores.resource.depara"),
          cb: row => {
            this.$router.push({
              name: "de-para-colaboradores",
              params: { colaboradorId: row.idColaborador }
            });
          }
        },
        {
          name: "sep",
          show: {
            get() {
              return (
                options.slice(0, 4).some(({ key }) => key) &&
                options.slice(5).some(({ key }) => key)
              );
            }
          }
        },
        {
          name: "Editar",
          limit: 1,
          show: this.userHasAccess("Colaboradores.resource.update"),
          cb: row => {
            this.$router.push({
              name: "edicao-colaborador",
              params: { colaboradorId: row.idColaborador }
            });
          }
        }
      ];

      return options.filter(({ show }) => show);
    }
  },
  methods: {
    doLoad() {
      if (!this.servico) {
        return;
      }

      this.resource
        .get({
          query: `competenciaIni=${this.competenciaFilter.ini}&competenciaFim=${this.competenciaFilter.fim}&trabalho=${this.servico}`
        })
        .then(response => {
          if (!response.error) {
            this.relatorio = response[0];
            this.relatorio.forEach((row, idx) => {
              //add nome do projeto no array de colaboradores para imprimir certo no relatório exportado
              row.colaboradores.map(
                (c, i) =>
                  (this.relatorio[idx].colaboradores[i].projeto =
                    row.projeto.titulo)
              );
            });
          }
        });
    },
    actContext(v, e) {
      this.showContext({
        left: e.clientX,
        top: e.clientY,
        val: v,
        menu: this.contextMenuOptions
      });
    },
    recalcularPeriodo() {
      this.resourceRecalculo
        .save({
          trabalho: this.servico,
          competenciaIni: this.intervalo[0],
          competenciaFim: this.intervalo[1]
        })
        .then(() =>
          this.$notify({
            group: "geral",
            duration: 5000,
            type: "success",
            title: "Recalculo solicitado.",
            text: "Teros irá refazer o cálculo em segundo plano."
          })
        );
    }
  },
  data() {
    return {
      competencia: [],
      intervalo: [],
      servico: null,
      opts: {
        dedicacao: {
          1: "Exclusivo",
          2: "Parcial"
        }
      },
      relatorio: []
    };
  },
  watch: {
    clientId() {
      this.doLoad();
    }
  }
};
</script>

<style lang="scss" scoped>
.tabela-resumo::v-deep thead > tr > th {
  &:nth-last-child(1),
  &:nth-last-child(2) {
    text-align: end !important;
  }
}

.total-geral-line {
  display: flex;
  position: sticky;
  bottom: 0;

  .total-geral-nome {
    text-transform: uppercase;
  }
}

.total-geral {
  display: table-cell;
  height: 3rem;
  padding: 0 1rem;
  font-weight: bold;
  line-height: 4;
  user-select: none;
  white-space: nowrap;
}

.total-geral-nome {
  width: 40%;
}

.total-geral-cpf,
.total-geral-escolaridade,
.total-geral-dedicacao,
.total-geral-horas,
.total-geral-valor {
  width: 12%;
}
</style>
